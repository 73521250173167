import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FerieOferty from "../components/ferieOferty"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "ferie" }}>
    <SEO title="Ferie 2025"/>
    <FerieOferty />
  </Layout>
)

export default SecondPage

